<template>
<div id="visit">
  <div class="visit-content">
    <div v-show="dataList.length == 0 && isFineshed" class="no-visit">
      <div class="no-visit_center">
        <img alt="" src="../../public/img/icons/icon.png" />
        <p class="no-visit_des">啊哦，您还没有访视</p>
      </div>
    </div>
    <div v-show="dataList.length" class="has-visit">
      <ul :style="{height:'100%',display:'inline-block'}">
        <li class="visit-item" v-for="(item,index) in dataList" :key="index">
          <div class="visit-item_header clearfix">
            <span>{{item.displayNameCn}}</span>
            <i class="iconfont icon-shezhi fr" @click="visitEdit(item)" :class="{'iDisabled':canDesignFrom||canEditForm}" title="编辑访视" style="cursor: pointer;"></i>
            <!-- <i class="iconfont icon-shezhi fr" @click="visitEdit(item)" :class="{'iDisabled':releaseStatus==1}"></i> -->
            <el-popconfirm class="fr" title="您确定要删除该访视吗？" @confirm="visitDelete(item)" :class="{'iDisabled':canDesignFrom}">
              <i slot="reference" class="iconfont icon-shanchu" :class="{'iDisabled':canDesignFrom||canEditForm}" title="删除" style="cursor: pointer;"></i>
            </el-popconfirm>
            <i v-show="item.type==='1'" :class="{'iDisabled':canDesignFrom||canEditForm}"  class="iconfont icon-list fr" title="计划访视"
               @click="visitPlan(item)"
               style="color: #0D83D8;">
            </i>
            <i v-show="item.selectionType==='Multiple'" class="iconfont icon-zhongfufuben fr" title="可重复"></i>
            <!-- <i v-show="item.type==='1'" :class="{'iDisabled':canDesignFrom||canEditForm}"  class="el-icon-thumb fr" title="推送设置"
               @click="visitPush(item)"
               style="color: #0D83D8;display:block;height:100%;line-height:56px">
            </i> -->
          </div>
          <div class="visit-item_content">
            <el-scrollbar style="height:100%">
              <draggable class="dragArea list-group" :list="item.subList" @change="draggableLog(item.subList)">
                <div class="visit-item_item clearfix" v-for="(formItem,formIndex) in item.subList" :key="formIndex">
                  <span @click="toFormEdit(formItem)" :class="{'iDisabled':canDesignFrom}" style="cursor: pointer;display: inline-block;width: 100%;">{{formItem.displayNameCn}}</span>
                  <i class="el-icon-crop fl" style="margin:15px 8px" :class="{'iDisabled':canDesignFrom}"></i>
                  <!-- <i class="el-icon-more fr" style="margin:18px 10px"></i> -->
                  <el-dropdown class="fr" style="height:30px" trigger="click" :hide-on-click="false">
                    <span class="el-dropdown-link" :class="{'iDisabled':canDesignFrom||canEditForm}">
                      <i class="el-icon-more" :class="{'iDisabled':canDesignFrom||canEditForm}"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <el-dropdown style="height:30px" trigger="click" size="small" @command="handleCommand" placement="bottom" :class="{'iDisabled':canDesignFrom}">
                          <span style="width:unset">
                            <i class="iconfont icon-yidong" :class="{'iDisabled':canDesignFrom}" title="移动到" style="cursor: pointer;"></i>移动到
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-input class="visit-cell_search" v-model="searchString" size="mini" suffix-icon="el-icon-search"></el-input>
                            <el-dropdown-item v-for="itemCli in tableCellCom(dataList,item)" :key="itemCli.code" :command="composeValue(item,itemCli,formItem)">
                              {{itemCli.displayNameCn}}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-dropdown style="height:30px" trigger="click" size="small" @command="handleCommandCopy" placement="bottom" :class="{'iDisabled':canDesignFrom}">
                          <span style="width:unset">
                            <i class="iconfont icon-fuzhi" :class="{'iDisabled':canDesignFrom}" title="复制到" style="cursor: pointer;"></i>复制到
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-input class="visit-cell_search" v-model="searchStringCopy" size="mini" suffix-icon="el-icon-search"></el-input>
                            <el-dropdown-item v-for="itemCli in tableCellCom(dataList,item)" :key="itemCli.code" :command="composeValue(item,itemCli,formItem)">
                              {{itemCli.displayNameCn}}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-popconfirm style="width:unset" title="您确定要删除该表单吗？" @confirm="formDelete(item,formItem)" :class="{'iDisabled':canDesignFrom}">
                          <i slot="reference" class="iconfont icon-shanchu" :class="{'iDisabled':canDesignFrom}" title="删除" style="cursor: pointer;">
                            <span :class="{'iDisabled':canDesignFrom}" style="margin-left:5px;font-size:14px;color:#606266;font-weight:500">删除</span></i>
                        </el-popconfirm>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <i class="iconfont icon-shezhi" @click="formEdit(formItem)" :class="{'iDisabled':canDesignFrom}" title="编辑表单" style="cursor: pointer;">
                          <span :class="{'iDisabled':canDesignFrom}" style="margin-left:5px;font-size:14px;color:#606266;font-weight:500">设置</span></i>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </draggable>
            </el-scrollbar>
          </div>
          <div class="visit-item_footer" @click="formAdd(item)" :class="{'disabled':canDesignFrom||canEditForm}">添加表单
          </div>
        </li>
      </ul>
    </div>
    <div v-show="dataList.length" class="has-visit_add">
      <div class="has-visit_addIcon" @click="visitAdd" :class="{'disabled':canDesignFrom||canEditForm}">
        <el-tooltip class="item" effect="light" content="添加访视" placement="bottom-start">
          <span>+</span>
        </el-tooltip>
      </div>
    </div>
  </div>
  <!-- 添加访视弹窗 -->
  <el-dialog :title="visitTitle" :visible.sync="dialogVisible" width="30%" custom-class="visit-dialog" @open="submitButtonDisabled = false">
    <el-form size="mini" ref="visitForm" :model="visitForm" :rules="visitRules" label-width="80px">
      <el-form-item label="访视阶段" prop="stage">
        <el-input placeholder="请输入访视阶段" clearable v-model="visitForm.stage"></el-input>
      </el-form-item>
      <el-form-item label="访视名称" prop="title">
        <el-input placeholder="请输入访视名称" clearable v-model="visitForm.title"></el-input>
      </el-form-item>
      <el-form-item label="访视编号" prop="id">
        <el-input :disabled="visitTitle==='编辑访视'" placeholder="请输入访视编号" clearable v-model="visitForm.id"></el-input>
      </el-form-item>
      <el-form-item label="访视类型" prop="type">
        <el-select :style="{'width':'100%'}" v-model="visitForm.type" placeholder="请选择研究类型">
          <el-option label="计划" value="1"></el-option>
          <el-option label="非计划" value="0"></el-option>
          <el-option label="通用" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可否重复" prop="selectionType">
        <el-select :style="{'width':'100%'}" v-model="visitForm.selectionType" :disabled="visitTitle==='编辑访视'" placeholder="请选择可否重复">
          <el-option label="可重复" value="Multiple"></el-option>
          <el-option label="不可重复" value="Single"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
      <el-button v-show="visitTitle==='新建访视'" size="mini" type="primary" @click="addVisitConfirm('visitForm')" :loading="submitButtonDisabled">确 定</el-button>
      <el-button v-show="visitTitle==='编辑访视'" size="mini" type="primary" @click="editVisitConfirm('visitForm')" :loading="submitButtonDisabled">保 存</el-button>
    </span>
  </el-dialog>

  <!-- 添加表单弹窗 -->
  <el-dialog :title="visitTitle" :visible.sync="dialogVisibleAdd" width="40%" custom-class="visit-dialog" @open="submitButtonDisabled = false">
    <el-form size="mini" ref="visitFormAdd" :model="visitFormAdd" :rules="visitRulesAdd" label-width="80px">
      <el-form-item label="表单类别" prop="selectionType">
        <el-select :style="{'width':'100%'}" v-model="visitFormAdd.category" placeholder="请选择表单类别">
          <el-option v-for="ee in selector.category" :key="ee.value" :label="ee.label" :value="ee.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="表单名称" prop="name">
        <el-input placeholder="请输入表单名称" clearable v-model="visitFormAdd.name"></el-input>
      </el-form-item>
      <el-form-item label="表单编号" prop="id">
        <el-input placeholder="请输入表单编号" clearable v-model="visitFormAdd.id" :disabled="visitTitle==='编辑表单'"></el-input>
      </el-form-item>
      <el-form-item label="表单类型" prop="selectionType">
        <el-select :style="{'width':'100%'}" v-model="visitFormAdd.selectionType" :disabled="visitTitle==='编辑表单'" placeholder="请选择可否重复">
          <el-option v-for="ee in selector.selectionType" :key="ee.value" :label="ee.label" :value="ee.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="visitFormAdd.selectionType=='Multiple'" label="设置默认重复次数">
        <el-input-number v-model="visitFormAdd.repeatNum" :min="0" :max="40" controls-position="right" label="描述文字"></el-input-number>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-checkbox v-model="visitFormAdd.allowAdd">允许继续添加</el-checkbox>
      </el-form-item>

      <el-form-item label="ePRO表单">
        <el-radio-group v-model="visitFormAdd.isEpro">
          <el-radio v-for="(e ,i ) in selector.isEpro" :value="e.value" :label="e.value" :key="i">
            {{e.text}}
          </el-radio>
        </el-radio-group>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogVisibleAdd = false">取 消</el-button>
      <el-button v-show="visitTitle==='添加表单'" size="mini" type="primary" @click="handleSaveForm('add','visitFormAdd')" :loading="submitButtonDisabled">确 定</el-button>
      <el-button v-show="visitTitle==='编辑表单'" size="mini" type="primary" @click="handleSaveForm('edit','visitFormAdd')" :loading="submitButtonDisabled">保 存</el-button>
    </span>
  </el-dialog>


  <!-- 添加访视计划 :rules="visitPlanRules" -->
  <el-dialog :title="visitTitle" :visible.sync="dialogVisiblePlan"  width="40%" custom-class="visit-dialog"
             @open="submitButtonDisabled = false">
    <el-scrollbar style="height:230px">
      <el-form size="mini" ref="visiblePlanFrom" :model="visiblePlanFrom"  :rules="visitPlanRules"  label-width="110px">
        <el-form-item label="当前访视:" prop="name">
          <span type="success">{{visiblePlanFrom.name}}</span>
        </el-form-item>
        <span>受限制的受试者分组:</span>
        <el-form-item v-for="(item,index) in visiblePlanFrom.testeeGroup"
                      :key="item.groupName"
                      :prop="'testeeGroup.'+index+'.groupValue'"
                      :label="item.groupName+':'"
                      :rules="[{required:true,message:'请选择'+item.groupName+'受试者组',trigger: 'blur,change'}]"
        >
          <el-select v-model="item.groupValue" :style="{'width':'100%'}"
                     :placeholder="'请选择'+item.groupName+'受试者组'">
            <el-option v-for="groupItem in item.data"
                       :key="groupItem.code"
                       :label="groupItem.dictName"
                       :value="groupItem.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联访视:" prop="sRelevanceCode">
          <el-select v-model="visiblePlanFrom.sRelevanceCode" @change="pickOption " :style="{'width':'100%'}" placeholder="无">
            <el-option v-for="groupItem in visiblePlanFrom.structureList"
                       :key="groupItem.code"
                       :label="groupItem.name"
                       :value="groupItem.code">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="visiblePlanFrom.flag" label="访视间隔:" ><!--required-->
          <el-col :span="6">
            <el-form-item prop="dInterval">
              <el-input type="number" v-model="visiblePlanFrom.dInterval"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">天</el-col>
          <el-col :span="6">
            <el-form-item prop="hInterval">
              <el-input type="number" placeholder="" v-model="visiblePlanFrom.hInterval"></el-input>
            </el-form-item>
          </el-col>

          <el-col class="line" :span="2">小时</el-col>
          <el-col :span="6">
            <el-form-item prop="mInterval">
              <el-input type="number" placeholder="" v-model="visiblePlanFrom.mInterval"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">分钟</el-col>
        </el-form-item>

        <el-form-item v-if="visiblePlanFrom.flag"  label="访视窗(之前):" ><!--required-->
          <el-col :span="6">
            <el-form-item prop="dFront">
              <el-input type="number"  v-model="visiblePlanFrom.dFront"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">天</el-col>
          <el-col :span="6">
            <el-form-item prop="hFront">
              <el-input type="number" v-model="visiblePlanFrom.hFront"></el-input>
            </el-form-item>
          </el-col>

          <el-col class="line" :span="2">小时</el-col>
          <el-col :span="6">
            <el-form-item prop="mFront">
              <el-input type="number"  v-model="visiblePlanFrom.mFront"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">分钟</el-col>
        </el-form-item>

        <el-form-item   v-if="visiblePlanFrom.flag"  label="访视窗(之后):"  >
          <el-col :span="6">
            <el-form-item prop="dLater">
              <el-input type="number"  v-model="visiblePlanFrom.dLater" ></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">天</el-col>
          <el-col :span="6">
            <el-form-item prop="hLater">
              <el-input type="number"   v-model="visiblePlanFrom.hLater"></el-input>
            </el-form-item>
          </el-col>

          <el-col class="line" :span="2">小时</el-col>
          <el-col :span="6">
            <el-form-item prop="mLater">
              <el-input type="number" v-model="visiblePlanFrom.mLater"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">分钟</el-col>
        </el-form-item>

        <el-form-item v-if="visiblePlanFrom.selectionType == 'Multiple'" label="重复频率(每隔):">
          <el-col :span="6">
            <el-form-item >
              <el-input type="number"   v-model="visiblePlanFrom.dRepetitionHz"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">天</el-col>
          <el-col :span="6">
            <el-form-item >
              <el-input type="number"   v-model="visiblePlanFrom.hRepetitionHz"></el-input>
            </el-form-item>
          </el-col>

          <el-col class="line" :span="2">小时</el-col>
          <el-col :span="6">
            <el-form-item>
              <el-input type="number"   v-model="visiblePlanFrom.mRepetitionHz"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">分钟</el-col>
        </el-form-item>

        <el-form-item  v-if="visiblePlanFrom.selectionType == 'Multiple'" label="重复次数:" >
          <el-col :span="6">
            <el-input type="number" placeholder=""  v-model="visiblePlanFrom.sRepetitionNum"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
    </el-scrollbar>

    <span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogVisiblePlan = false">取 消</el-button>
				<el-button  size="mini" type="primary"
                            @click="addPlanConfirm('visiblePlanFrom')" :disabled="submitButtonDisabled">确 定</el-button>
			</span>

  </el-dialog>

  <!-- 消息推送 -->
  <el-dialog title="受试者推送设置" :visible.sync="dialogPushVisible" @close="reSeting">
    <el-form  label-width="80px" :model="pushFrom" size="small">
      <el-form-item label="消息内容">
        <el-input v-model="pushFrom.messageContent" type="textarea" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="提醒事项">
         <el-checkbox-group v-model="pushFrom.msgSettings" v-if="checkbox.length > 0">
          <el-checkbox :label="check.fieldName" name="type" v-for="(check,index) in checkbox" :key="index" :checked="check.choice === 1" class="checkbox" @change="check.choice === 0? check.choice = 1: check.choice = 0">
            <span style="font-size:14px;margin-right:20px">{{check.fieldName}}</span>
            <el-button type="danger" icon="el-icon-delete" circle @click="deletemsgSettings(check,index)"></el-button>
          </el-checkbox>
        </el-checkbox-group>
        <el-input v-model="tip" v-if="isShowAdd" style="width:85%;margin-right:5%"></el-input>
        <el-button type="primary" @click='addmsgSettings'>添加</el-button>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogPushVisible = false" size="small">取 消</el-button>
      <el-button type="primary" @click="submitPush" size="small" :loading="isLoadingBtn">确 定</el-button>
    </div>
 </el-dialog>

</div>
</template>

<script>
import {
  getVisit,
  addVisit,
  editVisit,
  deleteVisit,
  addForm,
  editForm,
  deleteForm,
  moveForm,
  copyForm,
  sortForm,
  getPlan,
  savePlan,
  getPush,
  addPush
} from "@/api/visit";

import draggable from "vuedraggable";
import {
  Loading
} from 'element-ui';
import {
  SelectorDefine
} from '../utils/SelectorDefine'
import globalConst from '../utils/globalConst'
import {
  localData
} from "../utils/storage"
import {
  mapMutations,
  mapState
} from 'vuex';

let loadingInstance = null;
export default {
  name: 'Visit',
  components: {
    draggable
  },
  computed: {
    ...mapState(['dataList', 'isEdit', 'isFineshed']),
    sendDialogTitle() {
      return this.researchName + '发布'
    },
    canDesignFrom() {
      console.log(this.isEdit, globalConst.devEnv);
      // if (this.isEdit != 0) {
      //     return true
      // }
      // if (globalConst.devEnv) {
      //   return false;
      // } else {
      //   return this.releaseStatus == 1 || this.releaseStatus == 2 || this.releaseStatus == 3;
      // }
      return false
    },
    canEditForm() {
      if (this.isEdit != 0) {
        return true
      }
    },
    addFormParams() {
      return {
        registryEntityCode: this.$route.query.code,
        code: this.visitFormAdd.id,
        parentCode: this.visitItem.code,
        displayNameCn: this.visitFormAdd.name,
        name: this.visitFormAdd.name,
        selectionType: this.visitFormAdd.selectionType,
        category: this.visitFormAdd.category,
        repeatNum: this.visitFormAdd.repeatNum,
        allowAdd: this.visitFormAdd.allowAdd,
        isEpro: this.visitFormAdd.isEpro,
        status: '0',
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };
    },
    editFormParams() {
      return {
        pkId: this.visitFormAdd.pkId,
        registryEntityCode: this.$route.query.code,
        code: this.visitFormAdd.id,
        parentCode: this.visitFormAdd.parentCode,
        displayNameCn: this.visitFormAdd.name,
        name: this.visitFormAdd.name,
        selectionType: this.visitFormAdd.selectionType,
        category: this.visitFormAdd.category,
        repeatNum: this.visitFormAdd.repeatNum,
        allowAdd: this.visitFormAdd.allowAdd,
        isEpro: this.visitFormAdd.isEpro,
        status: '0',
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };
    },

  },
  data() {
    return {
      versionCode: '',
      isHistiry: false,
      formLabelWidth: '80px',
      dialogPushVisible:false,
      sendForm: {
        where: 1
      },
      pushFrom:{
        messageContent:'',
        msgSettings:[],
      },
      tip:'',
      checkbox:[],
      isShowAdd:false,
      sendDialogFormVisible: false,
      selector: {},
      loading: true,
      researchName: this.$route.query.name,
      releaseStatus: this.$route.query.release,
      visitTitle: '新建访视',
      dialogVisible: false,
      dialogVisibleAdd: false,
      dialogVisiblePlan:false,
      isLoadingBtn:false,
      visitForm: {
        title: '',
        id: '',
        type: '0',
        selectionType: 'Single',
        stage:'',
        data: []
      },
      visitRules: {
        title: [{
            required: true,
            message: '请输入访视名称',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 255,
            message: '长度在 1 到 255 个字符',
            trigger: 'blur'
          }
        ],
        id: [{
            required: true,
            message: '请输入访视编号',
            trigger: 'blur'
          },
          {
            pattern: /^[A-Za-z0-9]{1,255}$/,
            message: '请输入 255 位以内数字或字母'
          }
        ],
        type: [{
          required: true,
          message: '请选择访视类型'
        }],
        selectionType: [{
          required: true,
          message: '请选可否重复'
        }],
        stage: [
            {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
        ],
      },
      visitFormAdd: {
        name: '',
        id: '',
        selectionType: 'Single',
        category: '0',
        repeatNum: 0,
        allowAdd: true,
        isEpro: '0',
        parentCode: '',
      },
      visitRulesAdd: {
        name: [{
            required: true,
            message: '请输入表单名称',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 255,
            message: '长度在 1 到 255 个字符',
            trigger: 'blur'
          }
        ],
        id: [{
            required: true,
            message: '请输表单编号',
            trigger: 'blur'
          },
          {
            pattern: /^[A-Za-z0-9]{1,255}$/,
            message: '请输入 255 位以内数字或字母'
          }
        ],
        selectionType: [{
          required: true,
          message: '请选择可否重复'
        }],
      },
      visitTitle: '添加表单',
      searchString: '',
      searchStringCopy: '',
      //防止按钮重复点击
      submitButtonDisabled: false,
      visiblePlanFrom:{
            flag:false,
            registryEntityCode:'',
            selectionType: '',
            sRelevanceCode:'999',
            name:'',
            testeeGroup:[],
            structureList:[],
            sCode:'',
            sRelevanceName:'',
            //访视时间间隔
            dInterval:0,
            hInterval:0,
            mInterval:0,
            //访视窗之前sFront
            dFront:0,
            hFront:0,
            mFront:0,
            //访视窗之后
            dLater:0,
            hLater:0,
            mLater:0,
            //重复频率
            dRepetitionHz:0,
            hRepetitionHz:0,
            mRepetitionHz:0,
            //重复次数
            sRepetitionNum:0
      },
      visitPlanRules:{
            sRelevanceCode:[  {required: true, message: '请选择关联访视', trigger: 'blur'}],
            dInterval:[
                {required: true, message: '请输入访视间隔天数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
            hInterval:[
                {required: true, message: '请输入访视间隔小时数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
            mInterval:[
                {required: true, message: '请输入访视间隔秒数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
            dFront:[
                {required: true, message: '请输入访视窗(之前)天数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
            hFront:[
                {required: true, message: '请输入访视窗(之前)小时数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
            mFront:[
                {required: true, message: '请输入访视窗(之前)秒数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
            dLater:[
                {required: true, message: '请输入访视窗(之后)天数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
            hLater:[
                {required: true, message: '请输入访视窗(之后)小时数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
            mLater:[
                {required: true, message: '请输入访视窗(之后)秒数', trigger: 'blur'},
                {pattern: /^[0-9]{1,255}$/, message: '请输入不小于零的数字'}
            ],
      },
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler: function (n) {
        console.log(n);
        this.isHistiry = this.$route.query.history || false
      }
    }
  },
  created() {
    //初始数据源
    this.$localData('set', 'operatingEnv', 'test');
//    loadingInstance = Loading.service({fullscreen: true, text: 'Loading',});
    this.selector = SelectorDefine().Visit;
    if (this.$route.query.isEdit == 1) {
      // this.getVisitList();
    } else {

    }
  },

  methods: {
    ...mapMutations(['setIsEdit', 'setDataList']),
    //添加访视计划
    visitPlan(item){
       this.visitTitle='计划访视';
       this.visiblePlanFrom = this.$options.data.call(this).visiblePlanFrom;
       this.$refs.visiblePlanFrom && this.$refs.visiblePlanFrom.clearValidate();
       this.dialogVisiblePlan = true;
       this.visiblePlanFrom.selectionType=item.selectionType;
       this.visiblePlanFrom.name=item.name;
       this.visiblePlanFrom.sCode=item.code
       this.visiblePlanFrom.registryEntityCode=item.registryEntityCode
       const postData = {
          registryEntityCode: this.visiblePlanFrom.registryEntityCode,
          code:this.visiblePlanFrom.sCode,
          versionCode: JSON.parse(localStorage.getItem('versionCode'))
       };
       getPlan(postData).then( res => {
//          loadingInstance.close();
          let structureConfigMessageList= res.data.structureConfigMessageList
          let groupCodeList=[];
          //回显数据
          if(structureConfigMessageList){
             for(let item of structureConfigMessageList){
                groupCodeList=item.groupCodeList;
                this.visiblePlanFrom.sRelevanceCode=item.sRelevanceCode;
                if(this.visiblePlanFrom.sRelevanceCode&&this.visiblePlanFrom.sRelevanceCode!='999'){
                    this.visiblePlanFrom.flag=true;
                }
                let sInterval =item.sInterval.split(',')
                 this.visiblePlanFrom.dInterval=sInterval[0]
                 this.visiblePlanFrom.hInterval=sInterval[1]
                 this.visiblePlanFrom.mInterval=sInterval[2]
                 let sFront =item.sFront.split(',')
                 this.visiblePlanFrom.dFront=sFront[0]
                 this.visiblePlanFrom.hFront=sFront[1]
                 this.visiblePlanFrom.mFront=sFront[2]
                 let sLater =item.sLater.split(',')
                 this.visiblePlanFrom.dLater=sLater[0]
                 this.visiblePlanFrom.hLater=sLater[1]
                 this.visiblePlanFrom.mLater=sLater[2]
                 if(structureConfigMessageList.sRepetitionHz !=''){
                     let sRepetitionHz=item.sRepetitionHz.split(',')
                     this.visiblePlanFrom.dRepetitionHz=sRepetitionHz[0]
                     this.visiblePlanFrom.hRepetitionHz=sRepetitionHz[1]
                     this.visiblePlanFrom.mRepetitionHz=sRepetitionHz[2]
                      }
                      this.visiblePlanFrom.sRepetitionNum=item.sRepetitionNum
                  }
              }
             this.visiblePlanFrom.testeeGroup=res.data.groupList.reduce(function (total, item,index) {
                let groupValue=''
                if(groupCodeList){
                      groupValue =groupCodeList[index]
                  }
                item.groupValue = groupValue;
                item.data.unshift({'dictName':'不限','code':'999'})
                total.push(item);
                return total
              }, []);
              this.loading = false;
              res.data.structureList.unshift({'name':'无','code':'999'})
              this.visiblePlanFrom.structureList=res.data.structureList
              console.log("---- this.visiblePlanFrom.testeeGroup=--"+JSON.stringify(this.visiblePlanFrom.testeeGroup))
          }).catch(error => {
//              loadingInstance.close();
              this.loading = false;
              console.log(error)
          });
    },
    // 添加或修改访视计划
    addPlanConfirm(planName){
          this.$refs[planName].validate((valid) => {
              if (valid) {
                  this.submitButtonDisabled = true;
                  let groupMsg = this.visiblePlanFrom.testeeGroup.reduce(function (total, item) {
                      return total + item.groupValue + ','
                  }, '');
                  let sInterval =this.visiblePlanFrom.dInterval+","+this.visiblePlanFrom.hFront+","+this.visiblePlanFrom.mFront
                  let sFront =this.visiblePlanFrom.dFront+","+this.visiblePlanFrom.hFront+","+this.visiblePlanFrom.mFront
                  let sLater =this.visiblePlanFrom.dLater+","+this.visiblePlanFrom.hLater+","+this.visiblePlanFrom.mLater
                  let sRepetitionHz=''
                  let sRepetitionNum=''
                  //不为计划重复表单时 重复次数和重复频率为空
                  if(this.visiblePlanFrom.selectionType == 'Multiple'){
                      sRepetitionHz =this.visiblePlanFrom.dRepetitionHz+","+this.visiblePlanFrom.hRepetitionHz+","+this.visiblePlanFrom.mRepetitionHz
                      sRepetitionNum=this.visiblePlanFrom.sRepetitionNum;
                  }
                  const postData = {
                      registryEntityCode: this.visiblePlanFrom.registryEntityCode,
                      sName:this.visiblePlanFrom.name,
                      sCode:this.visiblePlanFrom.sCode,
                      sGroup:groupMsg,
                      sRelevanceName:"",
                      sRelevanceCode:this.visiblePlanFrom.sRelevanceCode,
                      sInterval:sInterval,
                      sFront:sFront,
                      sLater:sLater,
                      sRepetitionHz:sRepetitionHz,
                      sRepetitionNum:sRepetitionNum,
                      versionCode: JSON.parse(localStorage.getItem('versionCode'))
                  };
                  console.log("------参数列表-------"+JSON.stringify(postData));
                  savePlan(postData).then( res => {
                      this.$message({
                          type: 'success',
                          showClose: true,
                          message: res.msg,
                          center: true
                      });
                      this.dialogVisiblePlan=false;
                  }).catch(error => {
                      this.submitButtonDisabled = false;
                  });
              }else {
                  console.log('校验不通过 submit!!');
                  return false;
              }
          });
    },
    pickOption(val){
          if(val!='999'){
              this.visiblePlanFrom.flag=true
          }else {
              this.visiblePlanFrom.flag=false
          }
    },
    //消息推送  
    async visitPush(item){
      this.dialogPushVisible = true
      let result = await getPush(item.code)
      if(result.code === 200){
        result.data.messageContent = result.data.messageContent?result.data.messageContent:''
        result.data.msgSettings = result.data.msgSettings?result.data.msgSettings:[]
        this.pushFrom = result.data
        this.checkbox = JSON.parse(JSON.stringify(result.data.msgSettings))
      }
    },
    //
    reSeting(){
      this.isShowAdd = false
      this.pushFrom = {
        messageContent:'',
        msgSettings:[],
      },
      this.checkbox = []
    },
    async submitPush(){
      this.isLoadingBtn = true
      this.pushFrom.msgSettings = JSON.parse(JSON.stringify(this.checkbox))
      let result =await addPush(this.pushFrom)
      if(result.code === 200){  
        console.log(1)
        this.$message.success('添加成功')
        this.isLoadingBtn = false
        this.dialogPushVisible = false
      }
    },
    //添加提醒
    addmsgSettings(){
      if(this.isShowAdd){
       if(!this.tip){
         this.$message.error('请先输入值')
         this.isShowAdd = true
       }else{
          this.checkbox.push({
            pkId:null,
            fieldName:this.tip,
            choice:2,
          })
          this.$message.success('添加成功')
           this.isShowAdd = false
       }
      }else{
        this.tip = ''
        this.isShowAdd = true
      }
    },
    deletemsgSettings(item,index){
      this.checkbox.splice(index,1)
      this.$message.success('删除成功')
    },
    onSubmitSend() {
      this.sendDialogFormVisible = false
      console.log(this.sendForm);
    },
    draggableLog(listData) {
      let data = [];
      listData.forEach((item, index) => {
        let obj = {};
        obj.registryEntityCode = item.registryEntityCode;
        obj.code = item.code;
        obj.displayOrder = index + 1;
        data.push(obj);
      })
      const postData = {
        list: data
      };
      sortForm(data).then(res => {
        this.$message({
          type: 'success',
          showClose: true,
          message: '顺序更新成功',
          center: true
        });
      });
    },

    getVisitList() {
      const postData = {
        researchCode: this.$route.query.code,
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };

      getVisit(postData)
        .then(res => {
          console.log('获取访视列表', res);
          this.setDataList(res.data.dataList)
          this.dialogVisible = false;
          this.dialogVisibleAdd = false;
          // loadingInstance.close();
          this.loading = false;
        })
        .catch(error => {
          // loadingInstance.close();
          this.loading = false;
        });
    },
    //添加访视
    visitAdd() {
      this.visitTitle = '新建访视';
      // console.log(this.$regionOptions)
      this.visitForm = this.$options.data.call(this).visitForm;
      this.$refs.visitForm && this.$refs.visitForm.clearValidate();
      this.dialogVisible = true;
    },
    addVisitConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitButtonDisabled = true;
          const postData = {
            registryEntityCode: this.$route.query.code,
            parentCode: 'Root',
            displayNameCn: this.visitForm.title,
            name: this.visitForm.title,
            code: this.visitForm.id,
            type: this.visitForm.type,
            selectionType: this.visitForm.selectionType,
            stage:this.visitForm.stage,
            versionCode: JSON.parse(localStorage.getItem('versionCode'))
          };
          addVisit(postData)
            .then(res => {
              this.getVisitList();
              this.$message({
                type: 'success',
                showClose: true,
                message: res.msg,
                center: true
              });
            })
            .catch(error => {
              this.submitButtonDisabled = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //编辑访视
    visitEdit(item) {
      if (this.isEdit) {

      }
      this.editId = item.code;
      this.visitForm = {
        title: item.displayNameCn,
        id: item.code.split('_')[0],
        type: item.type,
        pkId: item.pkId || '',
        selectionType: item.selectionType,
        stage:item.stage|| ''
      }
      this.visitTitle = '编辑访视';
      this.dialogVisible = true;
    },
    editVisitConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitButtonDisabled = true;
          const postData = {
            pkId: this.visitForm.pkId,
            registryEntityCode: this.$route.query.code,
            parentCode: 'Root',
            displayNameCn: this.visitForm.title,
            name: this.visitForm.title,
            code: this.editId,
            type: this.visitForm.type,
            selectionType: this.visitForm.selectionType,
            versionCode: JSON.parse(localStorage.getItem('versionCode')),
            stage:this.visitForm.stage
          };
          editVisit(postData)
            .then(res => {
              this.getVisitList();
              this.$message({
                type: 'success',
                showClose: true,
                message: res.msg,
                center: true
              });
            })
            .catch(error => {
              this.submitButtonDisabled = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //删除访视
    visitDelete(item) {
      console.log(item);
      const postData = {
        // pkId: item.pkId
        registryEntityCode: item.registryEntityCode,
        sectionCode: item.code,
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };
      deleteVisit(postData)
        .then(res => {
          this.$message({
            type: 'success',
            showClose: true,
            message: res.data,
            center: true
          });
          this.getVisitList();
        })
        .catch(error => {

        });
    },
    //添加表单
    formAdd(visitItem) {

      this.visitTitle = '添加表单';
      this.visitItem = visitItem;
      this.visitFormAdd = this.$options.data.call(this).visitFormAdd;
      this.dialogVisibleAdd = true;
      this.$refs.visitFormAdd && this.$refs.visitFormAdd.clearValidate();
    },
    handleSaveForm(fnCode, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitButtonDisabled = true;
          if (fnCode == 'edit') {
            editForm(this.editFormParams)
              .then(res => {
                this.getVisitList();
                this.$errInfo(res.data, 'success');
                if (this.visitFormAdd.repeatNum) {
                        localStorage.setItem('groupCount',this.visitFormAdd.repeatNum)
                      }
              })
              .catch(error => {
                this.submitButtonDisabled = false;
              });
          }

          if (fnCode == 'add') {
            addForm(this.addFormParams)
              .then(res => {
                this.getVisitList();
                this.$errInfo(res.data, 'success');
              }).catch(error => {
                this.submitButtonDisabled = false;
              });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    formEdit(item) {
      this.$refs.visitFormAdd && this.$refs.visitFormAdd.clearValidate();
      this.editFormId = item.code;
      this.visitFormAdd = {
        pkId: item.pkId,
        id: item.code.split('_')[0],
        parentCode: item.parentCode,
        displayNameCn: item.displayNameCn,
        name: item.name,
        selectionType: item.selectionType,
        isEpro: item.isEpro,
        category: item.category,
        allowAdd: item.allowAdd == 'true',
        repeatNum: item.repeatNum,
      };
      this.visitTitle = '编辑表单';
      this.dialogVisibleAdd = true;
    },
    //删除表单
    formDelete(item, formItem) {
      const postData = {
        registryEntityCode: this.$route.query.code,
        sectionCode: formItem.code,
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };
      deleteForm(postData).then(res => {
        this.getVisitList();
        this.$errInfo(res.data, 'success');
      })
    },
    //移动表单
    handleCommand(command) {
      const postData = {
        pkId: command.formItem.pkId,
        registryEntityCode: this.$route.query.code,
        code: command.formItem.code,
        parentCode: command.itemCli.code,
        displayNameCn: command.formItem.name,
        name: command.formItem.name,
        selectionType: command.formItem.selectionType,
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };
      moveForm(postData)
        .then(res => {
          this.$errInfo('数据移动成功', 'success');
          this.getVisitList();
        })
        .catch(error => {

        });
    },
    //复制表单到
    handleCommandCopy(command) {
      const postData = {
        registryEntityCode: this.$route.query.code,
        code: command.formItem.code,
        parentCode: command.itemCli.code,
        displayNameCn: command.formItem.displayNameCn,
        name: command.formItem.name,
        selectionType: command.formItem.selectionType,
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };
      copyForm(postData).then(res => {
        this.getVisitList();
      })

    },
    composeValue(item, itemCli, formItem) {
      return {
        'item': item,
        'itemCli': itemCli,
        'formItem': formItem
      }
    },
    //移动表单搜索
    tableCellCom(data, exclude) {
      return data.filter(item => item.displayNameCn.indexOf(this.searchString) != -1 && item.displayNameCn !== exclude.displayNameCn);
    },
    toFormEdit(item) {
      localStorage.removeItem('fullPath')
      this.$router.push({
        name: 'formDesigin',
        query: {
          sectionCode: item.code,
          registryEntityCode: this.$route.query.code,
          name: item.displayNameCn,
          selectionType: item.selectionType,
          parentCode: item.parentCode,
          patientId: item.patientId,
          repeatNum: item.repeatNum,
        }
      })
    }
  }
  // beforeRouteLeave(to,form,next){
  //     console.log(to,form);
  //     if (to.name == 'visitHistory') {
  //         if (localStorage.getItem('isEdit') == 1) {
  //             to.meta.keepAlive = true
  //         } else {
  //             to.meta.keepAlive = false
  //         }
  //     }
  //     next()
  // }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #606266;
}

/deep/ .el-dropdown-menu__item {
  line-height: 30px;
}
.checkbox{
  display: block;
}
#visit {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3px;

  // background: rgba(73, 150, 255, 0.14);
  .common-header {
    background: #e9ecef;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    .left-wrap {
      width: 180px;
    }

    .right-wrap {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .common-btn {
    padding: 0 16px;
    box-sizing: border-box;
    margin-top: 12px;
  }

  .visit-title {
    display: inline-block;
    max-width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
  }

  .visit-addOrRelease {
    padding: 0 16px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #FFF;
    background: #2c5c89;
    border-radius: 2px;
    margin: 13px 10px;
    border: none;
  }

  .visit-content {
    flex: 1;
    height: 100%;
    top: 130px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    overflow-x: auto;
    white-space: nowrap;

    .no-visit {
      width: 100%;
      height: 100%;
      background: #fff;

      .no-visit_center {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      .no-visit_des {
        width: 220px;
        height: 24px;
        font-size: 24px;
        color: #96B0C3;
        line-height: 24px;
        margin: 16px auto 0;
      }
    }

    .has-visit {
      height: calc(100% - 20px);
      padding: 10px;
      background: #fff;
      display: inline-block;

      .visit-item {
        display: inline-block;
        height: 100%;
        width: 320px;
        border: 1px solid #e1e1e1;
        margin-right: 5px;
        border-radius: 5px;

        .visit-item_header {
          height: 56px;
          line-height: 56px;
          background: #EAECEF;
          color: #212121;
          padding-left: 10px;
          box-sizing: border-box;
          border-radius: 5px 5px 0px 0px;

          span {
            display: inline-block;
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            color: #000;
          }

          i {
            font-size: 21px;
            margin: -1px 4px;
            color: #2c5c89;

            &:nth-of-type(2),
            &:nth-of-type(3) {
              color: #2c5c89;
            }
          }
        }

        .visit-item_content {
          height: calc(100% - 106px);
          width: 100%;
          overflow-y: auto;
          margin-bottom: 10px;

          .visit-item_item {
            width: 300px;
            height: 46px;
            line-height: 46px;
            background: #EAECEF;
            margin: 5px auto;

            // text-align: center;
            span {
              display: inline-block;
              max-width: 150px;
              font-size: 16px;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            i {
              font-size: 20px;
              margin-right: 8px;
              color: #2c5c89;
            }
          }
        }

        .visit-item_footer {
          width: 300px;
          height: 32px;
          line-height: 32px;
          margin: 0 auto;
          background: #2c5c89;
          color: #FFF;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .has-visit_add {
      position: relative;
      display: inline-block;
      width: 70px;
      height: 100%;
      background: #fff;
      vertical-align: top;
      margin-left: 10px;
      text-align: center;

      .has-visit_addIcon {
        width: 28px;
        height: 28px;
        font-size: 24px;
        line-height: 24px;
        background: #2c5c89;
        color: #FFF;
        border-radius: 50%;
        border: 1px solid #2c5c89;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }

  }
}
</style>
<style lang="scss">
.el-dialog.visit-dialog {
  min-width: 256px;
}

.visit-cell_search {
  padding: 0 15px 8px;
  width: auto;
  border-bottom: 1px solid #e4e4e4;

  .el-input__suffix {
    right: 20px;
  }
}
</style>
